import { Category } from '@portal/wen-backend-api';
import { Filterable } from './filter-item';

export const createFilterFromCategory = (category: Category): Filterable => {
  return {
    id: category.id,
    name: category.name,
    filter: {
      categories: [category.id]
    }
  };
};
