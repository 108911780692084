import { Injectable } from '@angular/core';
import { OverlayManager } from '@portal/wen-components';
import { filter, first, map } from 'rxjs';
import { FilterSelectorResponse } from './filter-selector-response';
import { FilterSelectorComponent, FilterSelectorData } from './filter-selector.component';
import { deepMerge } from '../../../../../core/common/util/deepmerge';
import { TranslateService } from '@ngx-translate/core';
import { AllFilters } from '@portal/wen-backend-api';

@Injectable()
export class FilterSelectorOpener {

  constructor(
    private overlayManager: OverlayManager,
    private translateService: TranslateService
  ) { }

  openFilterSelector(dialogData: FilterSelectorData) {
    const dialogRef = this.overlayManager.dialog.openFilterSelectorDialog<
      FilterSelectorComponent,
      FilterSelectorData,
      FilterSelectorResponse
    >(FilterSelectorComponent, dialogData);
    return dialogRef.beforeClosed().pipe(
      first(),
      filter(dialogResult => Boolean(dialogResult)),
      map(({ selectedIds, customData }) => {
        if (dialogData.isMultiselect && selectedIds.length > 1) {
          const newActiveFilters = dialogData.visibleFilters
            .filter(possibleValues => selectedIds.includes(possibleValues.id))
            .reduce((acc, item, index, array) => ({
              id: [...acc.id, item.id],
              name: array.length > 1
                ? `${array.length} ${this.translateService.instant('CATEGORY_SELECTOR_LABEL')}`
                : item.name,
              filter: deepMerge(acc.filter, item.filter)
            }),
            { id: [], name: '', filter: {} } as { id: string[]; name: string; filter: AllFilters }
          );
          return newActiveFilters;
        }
        let newActiveFilter = dialogData.visibleFilters.find(possibleValues => possibleValues.id === selectedIds[0]);
        if (customData) {
          newActiveFilter = { ...newActiveFilter, filter: customData };
        }
        return newActiveFilter;
      })
    );
  }
}
