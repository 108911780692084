import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { WenBreakpointObserver } from '@portal/wen-components';
import { of } from 'rxjs';
import { finalize, first, map, tap } from 'rxjs/operators';
import { WenRouteId } from '../../../../frame/routing/types';
import { UserData } from '../../../store/auth/models/UserData';
import { RootState } from '../../../store/root/public-api';
import { loadImportedSelectionData, setCurrentCrmRootRoute, updateSmartDesignState } from '../../../store/smartdesign/smartdesign.actions';
import { SD_WEB_API_BACKEND_TYPE_QUERY_PARAM, SD_WEB_API_HOST_QUERY_PARAM } from '../../navigation/query-params';
import { WenNavigationHelper } from '../../navigation/types';
import { CRMBackendType } from '../helpers/evaluate-backend-type';
import { SmartDesignApi } from '../smartdesing-api';

@Injectable()
export class CrmConnectionInitializer {

  firstRun = true;

  constructor(
    private store: Store<RootState>,
    private smartDesignApi: SmartDesignApi,
    private navigationHelper: WenNavigationHelper,
    private breakpointObserver: WenBreakpointObserver,
  ) { }

  initialize(route: ActivatedRouteSnapshot, userData: UserData) {
    if (this.firstRun) {
      const sdHostParam = route.queryParamMap.get(SD_WEB_API_HOST_QUERY_PARAM) || null;
      const sdBackendTypeParam = route.queryParamMap.get(SD_WEB_API_BACKEND_TYPE_QUERY_PARAM) as CRMBackendType || null;
      this.store.dispatch(updateSmartDesignState({
        newState: {
          isFromSmartDesign: true,
          sdHostParam,
          backendType: sdBackendTypeParam
        }
      }));
    }

    if (Boolean(userData) && this.breakpointObserver.isDesktopStyleDevice && route.data.isCrmRoot) {
      this.store.dispatch(setCurrentCrmRootRoute({ routeId: route.data.routeId }));
      if (route.data.routeId === WenRouteId.CRM_ACTION_SELECTOR) {
        this.navigationHelper.navigateToCrmActionSelector();
        this.firstRun = false;
        return of(false);
      } else if (route.data.routeId === WenRouteId.CRM_DISTRIBUTE_CHAT) {
        this.navigationHelper.navigateToCrmDistributeChat();
        this.firstRun = false;
        return of(false);
      }
    }

    return this.smartDesignApi.ensureApiLoaded().pipe(
      first(),
      tap(() => this.store.dispatch(loadImportedSelectionData())),
      map(() => true),
      finalize(() => this.firstRun = false)
    );
  }

}

