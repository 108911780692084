import { Inject, Injectable, Optional, Provider } from '@angular/core';
import { ClientCustomizationProvider } from '../../../frame/network/tokens';
import { ColorPalette, GreyPalette } from '../../../shared/directives/colorize/palette-provider';
import { WenClientStyleConfig, WenClientWeatherConfig } from './customization-types';
import { WEATHER_CONFIG, WeatherConfig } from '../../../frame/api/tokens';
import { HomeCoverImage } from '@portal/wen-backend-api';

@Injectable()
export class WenClientCustomization extends ClientCustomizationProvider {

  constructor(@Optional() @Inject(WEATHER_CONFIG) private weatherConfig: WeatherConfig) {
    super();
  }

  getDefaultStyleConfig() {
    const defaults: WenClientStyleConfig = {
      primaryColor: ColorPalette.RED,
      logo: '/assets/wen-widget/image/we-network-logo.svg',
      welcomeLogo: '/assets/wen-widget/image/we-network-logo.svg',
      channelViewBackgroundImage: '/assets/wen-widget/image/channel-background.svg',
      channelViewBackgroundColor: GreyPalette.GREY9,
      chatViewBackgroundColor: ColorPalette.WHITE,
      headerTextColor: GreyPalette.GREY8,
      headerSubTitleColor: GreyPalette.GREY7,
      headerBackgroundColor: GreyPalette.WHITE2,
      headerCommandColorBack: 'var(--wen-primary-color)',
      headerCommandColorCreate: 'var(--wen-primary-color)',
      headerCommandColorWizardNext: 'var(--wen-primary-color)',
      headerCommandColorOpenProfile: GreyPalette.GREY6,
      headerCommandColorClose: GreyPalette.GREY6,
      searchBarCancelButtonColor: 'var(--wen-primary-color)',
      searchBarTextColor: GreyPalette.GREY4,
      searchBarBackgroundColor: GreyPalette.GREY2,
      errorColor: ColorPalette.RED2,
      linkColor: ColorPalette.DARK_BLUE,
      chatMessageboxBackgroundColorHighlighted: ColorPalette.DARK_BLUE,
      chatMessageboxTextColorHighlighted: ColorPalette.WHITE,
      chatMessageboxTextColorLightHighlighted: ColorPalette.WHITE,
      chatMessageboxLinkColorHighlighted: ColorPalette.WHITE,
      chatMessageboxErrorColorHighlighted: ColorPalette.ORANGE,
    };

    return defaults;
  }

  getDefaultWeatherConfig(): WenClientWeatherConfig {
    return {
      location: this.weatherConfig?.location,
      countryCode2Letters: this.weatherConfig?.countryCode2Letters,
      goToLink: this.weatherConfig?.goToLink
    };
  }

  getDefaultHomeCoverImageConfig(): HomeCoverImage {
    return {
      landscape: '/assets/wen-widget/image/home/home-cover-landscape.jpg',
      portrait: '/assets/wen-widget/image/home/home-cover-portrait.jpg'
    };
  }
}

export const WEN_CLIENT_CUSTOMIZATION_PROVIDER: Provider = {
  provide: ClientCustomizationProvider,
  useClass: WenClientCustomization
};
