import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChannelType } from '@portal/wen-backend-api';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { selectCurrentChannel, selectSubscribedChannelIds } from '../../../core/store/channel/channel.selectors';
import { upsertHeaderData } from '../../../core/store/header/header.actions';
import { HeaderData } from '../../../core/store/header/models/HeaderData';
import { RootState } from '../../../core/store/root/public-api';

@Injectable()
export class ChannelHeaderDataUpdater implements OnDestroy {

  private onDestroy$ = new Subject<void>();
  public readonly headerData$ = combineLatest([
    this.store.pipe(select(selectCurrentChannel)),
    this.store.pipe(select(selectSubscribedChannelIds)),
  ]).pipe(
    filter(([currentChannel]) => Boolean(currentChannel)),
    debounceTime(0),
    map(([currentChannel, subscribedChannelIds]) => {
      const title = currentChannel.title;
      const subTitle =
        currentChannel.channelType === ChannelType.NORMAL ?
        `${currentChannel.subscriptions || 0} ${this.translateService.instant('SUBSCRIBER', { count: currentChannel.subscriptions })}` : '';
      const isSubscribed = subscribedChannelIds.includes(currentChannel.id);
      const titleIcon = isSubscribed && currentChannel.isMuted ?
        { name: 'mute', altText: this.translateService.instant('CHANNEL_MUTED_ICON') } : null;
      const prefixTitleIcon = (!subscribedChannelIds.includes(currentChannel.id) &&
        currentChannel.isPrivate && !currentChannel?.invite) ?
        { name: 'lock', altText: this.translateService.instant('CHANNEL_RESTRICTED_ICON') } : null;
      const headerData: HeaderData = { title, subTitle, titleIcon, prefixTitleIcon };
      return headerData;
    })
  );

  constructor(
    private store: Store<RootState>,
    private translateService: TranslateService
  ) {
  }

  initialize() {
    this.headerData$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((headerData) => {
      this.store.dispatch(upsertHeaderData({ headerData }));
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
