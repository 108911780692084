import { UserData } from '../../../../core/store/auth/models/UserData';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ChannelMessageDTO } from '@portal/wen-backend-api';
import { combineLatest, distinctUntilChanged, filter, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { firstExisty } from '../../../../core/common/operators/first-existy';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { selectChannelById, selectMessageDetailById } from '../../../../core/store/channel/channel.selectors';
import { ChannelEntity } from '../../../../core/store/channel/channel.state';
import { RootState } from '../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../core/store/root/root.selectors';
import { PageRequestEvent, WeFeedDataset, WeFeedItemType, WeFeedMessageItem } from '../../../../shared/components/feed/components/we-feed/we-feed-model';
import { FeedDatasource } from '../../../../shared/components/feed/providers/feed-datasource';
import { ChannelConfigurationProvider } from '../../common/providers/channel-configuration';
import { channelMessageDetailIdentifier } from '../../tokens';

@Injectable()
export class ChannelMessageDetailDatasource extends FeedDatasource {

  public message$: Observable<ChannelMessageDTO>;
  public channel$: Observable<ChannelEntity>;
  public disableEmojiReaction$: Observable<boolean>;
  public commentsEnabled$: Observable<boolean>;
  private isPersonalChannel$: Observable<boolean>;

  constructor(
    protected store: Store<RootState>,
    private channelConfigurationProvider: ChannelConfigurationProvider,
  ) {
    super(store);
    this.message$ = this.store.pipe(
      select(selectRouteParam(channelMessageDetailIdentifier)),
      distinctUntilChanged(),
      switchMap((messageId) => this.store.pipe(
        selectorWithParam(selectMessageDetailById, messageId),
        filter(message => Boolean(message)),
        firstExisty(),
        shareReplay(1)
      ))
    );
    this.channel$ = this.message$.pipe(
      switchMap((message) => {
        return this.store.pipe(
          selectorWithParam(selectChannelById, message.channelId),
        );
      })
    );
    this.disableEmojiReaction$ = this.channel$.pipe(
      map(channel => {
        return this.channelConfigurationProvider.getDisableEmojiReactionForChannel(channel);
      })
    );
    this.commentsEnabled$ = this.channel$.pipe(
      map(channel => {
        return this.channelConfigurationProvider.getCommentsEnabledForChannel(channel);
      })
    );

    this.isPersonalChannel$ = this.channel$.pipe(
      map(channel => {
        return this.channelConfigurationProvider.getChannelIsPersonal(channel);
      })
    );
  }

  bindToSource(): Observable<WeFeedDataset> {
    return combineLatest([
      this.message$,
      this.isPersonalChannel$,
      this.channel$,
      this.disableEmojiReaction$,
      this.commentsEnabled$,
      this.currentUser$
    ]).pipe(
      map(([message, isPersonalChannel, currentChannel, disableEmojiReaction, commentsEnabled, currentUser]) => {
        const item = this.convertToFeedItem(message, isPersonalChannel, currentChannel, disableEmojiReaction, commentsEnabled, currentUser);
        const dataSet: WeFeedDataset = {
          items: [item]
        };
        return dataSet;
      })
    );
  }

  loadNextPage(event: PageRequestEvent): Observable<{ hasResult: boolean }> {
    return of({ hasResult: false });
  }

  loadFirstPage(): Observable<{ hasResult: boolean }> {
    return of({ hasResult: false });
  }

  private convertToFeedItem(message: ChannelMessageDTO,
    isPersonalChannel: boolean,
    currentChannel: ChannelEntity,
    disableEmojiReaction: boolean,
    commentsEnabled: boolean,
    currentUser: UserData
  ) {
    const {
      id,
      authorId,
      userId,
      content,
      scheduled,
      embeds,
      timestamp,
      formattedUpdateUserId,
      type,
      updateTimestamp,
    } = message;
    const lastEditorId = formattedUpdateUserId ?? authorId;
    const feedItem: WeFeedMessageItem = {
      key: message.id,
      type: WeFeedItemType.MESSAGEBOX,
      value: {
        messageId: id,
        referenceId: id,
        authorId,
        currentUserUpdater: currentUser.userId === lastEditorId,
        sender: userId,
        senderVisible: true,
        content,
        scheduled,
        embeds,
        timestamp,
        shareVisible: true,
        commentsVisible: commentsEnabled,
        commentsEnabled,
        highlighted: false,
        wide: false,
        disableEmojiReaction,
        hideEmojiReactionPlaceholder: false,
        status: null,
        modificationState: type,
        updateTimestamp,
        disableNavigationToSender: isPersonalChannel,
        textToSpeechEnabled: false,
        challengeEnabled: false,
        avatarIcon: isPersonalChannel ? currentChannel.icon : null
      },
    };
    return feedItem;
  }

}
