import { Component, OnInit, forwardRef, inject } from '@angular/core';
import { DialogLayoutModule } from '@portal/wen-components';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogActionHandler, SelectionOption, WenIconModule, WenSelectionListModule, WenTestIdModule } from '@portal/wen-components';
import { CommonModule } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { EventICalSettingsProvider } from '../event-detail/providers/event-ical-settings-provider';
import { SharedModule } from '../../../../../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventICalEventPickerResultData, Recur } from '../../models/event-detail.models';


@Component({
  selector: 'wen-event-ical-event-picker',
  standalone: true,
  imports: [
    DialogLayoutModule,
    TranslateModule,
    WenIconModule,
    CommonModule,
    WenTestIdModule,
    MatDialogModule,
    WenSelectionListModule,
    SharedModule,
    MatFormFieldModule,
  ],
  templateUrl: './event-ical-event-picker.component.html',
  providers: [
    EventICalSettingsProvider,
    {
      provide: ConfirmationDialogActionHandler,
      useExisting: forwardRef(() => EventIcalEventPickerComponent)
    }
  ]
})
export class EventIcalEventPickerComponent implements OnInit, ConfirmationDialogActionHandler {

  private dialogRef: MatDialogRef<EventIcalEventPickerComponent, EventICalEventPickerResultData> = inject(MatDialogRef);
  private iCalService: EventICalSettingsProvider = inject(EventICalSettingsProvider);

  reccuringForm = new FormGroup({
    recur: new FormControl(Recur.DISABLED)
  });

  recurModel: SelectionModel<SelectionOption<Recur>>;
  recurItems: SelectionOption<Recur>[];

  ngOnInit(): void {
    this.setupRecur();
  }

  onAccept() {
    const userOption = this.reccuringForm.controls.recur.value;
    const recur = userOption.includes(Recur.ENABLED);
    this.dialogRef.close({ result: 'ok', recur });
  }

  onDismiss() {
    this.dialogRef.close({ result: 'dismiss' });
  }

  onSelectionChanged(changedValue: Recur) {
    this.reccuringForm.controls.recur.markAsDirty();
    this.reccuringForm.controls.recur.setValue(changedValue);
  }

  private setupRecur() {
    this.recurItems = this.iCalService.getRecurSettingItems();
    const initial = this.recurItems.filter(item => item.id === Recur.DISABLED);
    this.recurModel = new SelectionModel(false, initial);
  }
}
