import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { EmbeddedQuoteDTO, QuoteData } from '@portal/wen-backend-api';
import { combineLatest, distinctUntilChanged, map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { MessageSendFieldDataSource } from '../../providers/message-send-field-data-source';

interface QuoteItem {
  data: QuoteData;
  isEditMode: boolean;
}

@Component({
  selector: 'wen-quote-embed-preview',
  templateUrl: './quote-embed-preview.component.html',
  styleUrls: ['./quote-embed-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteEmbedPreviewComponent {

  private dataSource = inject(MessageSendFieldDataSource);

  private quoteData$: Observable<QuoteData> = this.dataSource.draftMessage$.pipe(
    map(draftMessage => {
      if (draftMessage?.quote) {
        return (draftMessage?.quote as EmbeddedQuoteDTO)?.quoteData;
      }
      return null;
    }),
    shareReplay(1)
  );

  private isEditMode$: Observable<boolean> = this.dataSource.draftMessage$.pipe(
    map(message => Boolean(message?.id)),
    distinctUntilChanged(),
  );

  quoteItem$: Observable<QuoteItem> = combineLatest([this.quoteData$, this.isEditMode$]).pipe(
    map(([quoteData, isEditMode]) => {
      if (!quoteData) {
        return null;
      }

      return {
        data: quoteData,
        isEditMode
      };
    })
  );

  handleDismiss() {
    this.dataSource.updateDraftMessage({ quote: null });
  }

}
