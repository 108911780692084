@if (availableEmojis$ | async; as availableEmojis) {
  @if (reactionData$ | async; as reactionData) {
    <div class="emojis-wrapper">
      @for (reaction of reactionData.reactions; track trackByFn($index, reaction)) {
        <wen-action (click)="onAddReaction(reactionData.userSelection, reaction.emojiId)" class="wen-emoji-wrapper-action">
          <div class="wen-emoji-wrapper">
            <div [id]="emojiHtmlIds[reaction.emojiId]" class="wen-emoji-content">
              <wen-emoji [id]="reaction.emojiId"
                [selected]="reaction.emojiId === reactionData.userSelection"
                [wen-test-id]="'wen-emoji-' + reaction.emojiId">
              </wen-emoji>
            </div>
            <span class="emoji-counter" portal-subheading-1>{{ reaction.count | thousandFormat }}</span>
          </div>
        </wen-action>
      }
      <wen-action class="wen-add-emoji wen-emoji-action"
                  wenVariant="textSubtle"
                  iconName="add_reaction"
                  [altText]="'ADD_EMOJI_REACTION_LABEL' | translate"
                  (click)="handleAddReactionClick()">
      </wen-action>
    </div>
  } @else {
    @if (showPlaceholder) {
      @for (id of availableEmojis; track id) {
        <wen-action class="wen-emoji-action wen-emoji-wrapper-placeholder" (click)="onNewReaction(id)">
          <wen-emoji [id]="id" [wen-test-id]="'wen-emoji-' + id">
          </wen-emoji>
        </wen-action>
      }
    }
  }
}
