import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { initializeEventFilters, reloadEventDateFilters, upsertCategories } from '../event.actions';
import { first, map, merge, switchMap, tap } from 'rxjs';
import { DateCategory, EventDatesLoader } from '../../../../views/events/providers/event-dates-loader';
import { FilterEntityType } from '../../filter/models/filter';
import { createFilterFromCategory } from '../../../../shared/components/filter-view/models/category';
import { updateSection } from '../../sections/sections.actions';
import { SectionEntity } from '../../sections/sections.state';
import { FilterId } from '../../../common/types/filter-id';

@Injectable()
export class EventFiltersEffects {

  public readonly dateSections: DateCategory[] = [
    DateCategory.TODAY,
    DateCategory.TOMORROW,
    DateCategory.THIS_WEEKEND,
    DateCategory.NEXT_WEEK
  ];

  initializeEventDateFilters$ = createEffect(() => {
    const initialLoad$ = this.action$.pipe(ofType(initializeEventFilters), first());
    const reload$ = this.action$.pipe(
      ofType(reloadEventDateFilters),
      tap(() => this.eventDatesLoader.loadEventDateFilters())
    );
    return merge(
      initialLoad$,
      reload$
    ).pipe(
      map(() => {
        const dateFilters = this.eventDatesLoader.loadedDateFilters;
        const dateCategoryValues = Object.values(DateCategory);
        const dateFiltersAsSections: SectionEntity[] = dateFilters
          .map((dateFilter, index) => ({
            filterId: FilterId.EVENT_DATES,
            filter: dateFilter.filter,
            name: dateFilter.name,
            category: dateCategoryValues[index],
            id: dateFilter.id
          }))
          .filter(section => this.dateSections.includes(section.category))
          .map((dF) => {
            // eslint-disable-next-line unused-imports/no-unused-vars
            const { category, ...filterData } = dF;
            return {
              id: dF.id,
              filter: filterData,
              name: dF.name,
              filterType: FilterEntityType.EVENT_LISTS,
              filterId: dF.filterId
            };
          });
        return updateSection({ sections: dateFiltersAsSections });
      })
    );
  });

  initializeEventCategoryFilters$ = createEffect(() => {
    return this.action$.pipe(
      ofType(initializeEventFilters),
      first(),
      switchMap(() => {
        return this.socketIoService.events.getCategories.acknowledgement$();
      }),
      switchMap((categories) => {
        const eventCategoriesAsSections: SectionEntity[] = categories.map(category => {
          const categoryAsFilter = createFilterFromCategory(category);
          return {
            id: category.id,
            name: category.name,
            filter: categoryAsFilter,
            filterType: FilterEntityType.EVENT_LISTS,
            filterId: FilterId.EVENT_CATEGORIES
          };
        });
        return [
          upsertCategories({ categories }),
          updateSection({ sections: eventCategoriesAsSections })
        ];
      })
    );
  });

  constructor(
    private action$: Actions,
    private socketIoService: SocketIoService,
    private eventDatesLoader: EventDatesLoader,
  ) { }
}
