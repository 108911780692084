<div class="wen-event-detail-layout" cdkScrollable>
  @if (headerData$ | async; as headerData) {
    <wen-detail-header [model]="headerData" [coverImageStyle]="'square'" [shouldAnimate]="isMobileView" coverImageFitStyle="contain">
      <div wen-detail-header-title [class.wen-event-detail-header-has-title-icon]="headerData.prefixTitleIcon">
        @if (headerData.prefixTitleIcon) {
          <wen-icon class="wen-app-header-title-prefix-icon wen-icon-small" [iconName]="headerData.prefixTitleIcon.name"
                    [altText]="headerData.prefixTitleIcon.altText"></wen-icon>
        }
        <h2 portal-title>{{ headerData.title }}</h2>
      </div>
      <wen-event-subscribe-button wen-detail-header-quick-actions [subscription]="currentEventSubscription$ | async"></wen-event-subscribe-button>
      @if (eventDeepLink) {
        <wen-share-button wen-detail-header-quick-actions [deepLink]="eventDeepLink"></wen-share-button>
      }
      @if (canForward$ | async) {
        <wen-forward-button wen-detail-header-quick-actions [deepLink]="eventDeepLink" [context]="forwardContext"></wen-forward-button>
      }
      @if(currentEvent$ | async; as currentEvent){
        <wen-event-ical-button wen-detail-header-quick-actions [currentEvent]="currentEvent" [deepLink]="eventDeepLink"></wen-event-ical-button>
      }
    </wen-detail-header>
  }
  @if (currentEvent$ | async; as currentEvent) {
    <div class="wen-box">
      <div>
        <h3 portal-caption>{{ "EVENT_DETAIL_TIME_AND_PLACE" | translate }}</h3>
        <div class="wen-has-icon">
          <wen-icon iconName="event_checkmark" class="wen-icon-small"></wen-icon>
          <span portal-body  class="wen-one-line">
            {{ currentEvent.start | eventDate: currentEvent.end }}
          </span>
        </div>
        @if (rruleText) {
          <div portal-subheading-1 class="wen-align-as-has-icon">
            {{rruleText}}
          </div>
        }
        @if (currentEvent.locationContact?.name || currentEvent.locationContact?.addressLabel) {
          <div class="wen-has-icon">
            <wen-icon iconName="location" class="wen-icon-small"></wen-icon>
            <span portal-body class="wen-one-line">
              {{
              currentEvent.locationContact?.name
              ? currentEvent.locationContact?.name
              : currentEvent.locationContact?.addressLabel
              }}
            </span>
          </div>
        }
        @if (currentEvent.locationContact?.name && currentEvent.locationContact?.addressLabel) {
          <div
            portal-subheading-1
            class="wen-align-as-has-icon">
            {{ currentEvent.locationContact?.addressLabel }}
          </div>
        }
        @if (currentEvent.locationContact?.url) {
          <div portal-body class="wen-wrap-text wen-align-as-has-icon">
            <a (click)="openUrl(currentEvent.locationContact?.url)">{{ currentEvent.locationContact?.url}}</a>
          </div>
        }
        @if (locationUrl$ | async) {
          <iframe #iframe></iframe>
        }
      </div>
    </div>
    <div class="wen-box">
      @if (currentEvent?.description; as description) {
        <div>
          <h3 portal-caption>{{ "EVENT_DETAIL_DESCRIPTION" | translate }}</h3>
          <wen-expandable-container maxHeight="300">
            <div portal-body [innerHtml]="description"></div>
          </wen-expandable-container>
        </div>
      }
      @if (currentEvent?.categories; as categories) {
        <div>
          <wen-read-categories-field
            [fieldLabel]="'EVENT_DETAIL_CATEGORIES' | translate"
            [categories]="categories">
          </wen-read-categories-field>
        </div>
      }
      @if (currentEvent?.url; as url) {
        <div>
          <h3 portal-caption>{{ "EVENT_DETAIL_LINK" | translate }}</h3>
          <div portal-body class="wen-wrap-text">
            <a (click)="openUrl(url)">{{ url }}</a>
          </div>
        </div>
      }
      @if (currentEvent?.organizerContact; as organizer) {
        <div>
          <h3 portal-caption>{{ "EVENT_DETAIL_ORGANIZER" | translate }}</h3>
          <div portal-body class="wen-one-line">{{ organizer.name }}</div>
          <div portal-body class="wen-wrap-text">
            <a (click)="openUrl(organizer.url)">{{ organizer.url }}</a>
          </div>
          @if (organizer.email; as email) {
            <div portal-body class="event-organizer-contact-information wen-one-line">
              <span with-colon>{{ "EMAIL" | translate}}</span>
              <a (click)="mailTo(email)">{{ email }}</a>
            </div>
          }
          @if (organizer.phone; as phone) {
            <div portal-body class="event-organizer-contact-information wen-one-line">
              <span with-colon>{{ "PHONE" | translate }}</span>
              <a (click)="callPhoneNumber(phone)">{{ phone }}</a>
            </div>
          }
          <div portal-body  class="wen-one-line">{{ organizer.addressLabel }}</div>
        </div>
      }
    </div>
  }
</div>
