<form wenFormStore class="wen-form" [formGroup]="profileEditFormGroup" wen-test-id="wen-profile-edit-group">
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper wen-image-upload">
      <wen-image-upload [formControlName]="'avatarUrl'" [fallbackText]="fullName$ | async"></wen-image-upload>
    </div>
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'PROFILE_FORM_CHRISTIANNAME_LABEL' | translate }}</mat-label>
        <portal-field
          #christianName
          [formControlName]="'christianName'"
          [autocomplete]="'off'"
          [valueAdapter]="fieldValueAdapter"
          [required]="true"
          wenIosKeyboardStickyTarget
        [wen-test-id]="'profile-edit-christianname'"></portal-field>
        <mat-error portal-caption>{{ "PROFILE_FORM_CHRISTIANNAME_ERROR" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'PROFILE_FORM_LASTNAME_LABEL' | translate }}</mat-label>
        <portal-field
          #lastName
          [formControlName]="'lastName'"
          [autocomplete]="'off'"
          [valueAdapter]="fieldValueAdapter"
          [required]="true"
          wenIosKeyboardStickyTarget
        [wen-test-id]="'profile-edit-lastname'"></portal-field>
        <mat-error portal-caption>{{ "PROFILE_FORM_LASTNAME_ERROR" | translate }}</mat-error>
      </mat-form-field>
    </div>
    @if (isAboutYouFieldEnabled$ | async) {
      <div class="wen-form-field-wrapper wen-form-field-textarea">
        <mat-form-field>
          <mat-label>{{ 'PROFILE_FORM_ABOUT_ME_LABEL' | translate }}</mat-label>
          <div class="wen-textarea-wrapper">
            <textarea
              [formControlName]="'additionalInformation'"
              maxlength="{{ maximumCharacterLength }}"
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="8"
              wenIosKeyboardStickyTarget>
            </textarea>
            @if (itemCount$ | async) {
              <wen-action class="wen-action-no-padding" wenVariant="textSubtle" iconSize="medium" iconName="close" (click)="clearInput()"></wen-action>
            }
          </div>
          @if (itemCount$ | async; as itemCount) {
            <mat-hint align="end" class="wen-description-item-count" [align]>
              {{ itemCount }}/{{ maximumCharacterLength }}
            </mat-hint>
          }
        </mat-form-field>
      </div>
    }
    @if (isProfileExtensionEnabled$ | async) {
      <div class="wen-form-field-wrapper">
        <mat-form-field>
          <mat-label>{{ 'PROFILE_FORM_ADDRESS_LABEL' | translate }}</mat-label>
          <portal-field
            [value]="address$ | async"
            [autocomplete]="'off'"
            [readonly]="true"
            [wen-test-id]="'profile-edit-address'"
            (click)="openLocationSelector()"></portal-field>
          @if (address$ | async) {
            <wen-action class="wen-action-no-padding" wenVariant="textSubtle" iconSize="medium" iconName="close" matSuffix (click)="clearAddress($event)"></wen-action>
          }
          @else {
            <wen-action class="wen-action-no-padding" wenVariant="textSubtle" iconSize="medium" iconName="add_location" matSuffix (click)="openLocationSelector()"></wen-action>
          }
          <mat-hint align="end" class="wen-profile-current-location-picker" wen-primary-color (click)="useCurrentLocation()">
            <wen-icon class="wen-icon-medium" wen-primary-color iconName="current_location"></wen-icon>
            <span>{{ 'PROFILE_FORM_CURRENT_LOCATION_BUTTON_LABEL' | translate }}</span>
          </mat-hint>
        </mat-form-field>
      </div>
      @if(address$ | async) {
        <div class="wen-form-field-wrapper">
          <mat-checkbox [formControlName]="'addressPublic'" [disableRipple]="true">
            {{ 'PROFILE_FORM_HIDE_ADDRESS_LABEL' | translate }}
          </mat-checkbox>
        </div>
      }
      <div class="wen-form-field-wrapper">
        <mat-form-field>
          <mat-label>{{ 'PROFILE_FORM_COMPANY_LABEL' | translate }}</mat-label>
          <div class="wen-textarea-wrapper">
          <textarea
            [formControlName]="'company'"
            wenIosKeyboardStickyTarget
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="1"
            [wen-test-id]="'profile-edit-company'"></textarea>
          </div>
        </mat-form-field>
      </div>
    }
  </div>
  <div class="wen-form-actions">
    <wen-interact-button class="wen-interact-button-menu-style wen-error-interact-button" (click)="deleteProfile()">{{
    "USER_PROFILE_DELETE_PROFILE_BUTTON_LABEL" | translate }}</wen-interact-button>
  </div>
</form>
