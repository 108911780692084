<wen-form-progress [hasProgress]="hasProgress$ | async"></wen-form-progress>
<wen-loading-skeleton class="wen-chat-creation-slot-wrapper" [isLoaded]="true" wenFocusFirstInput wenIosViewportFix>
  <div class="wen-chat-creation-wrapper" wen-loaded-content>
    <wen-input-with-chips cdkScrollable tabindex="-1" wenIosKeyboardScrollable
      [prefixTranslation]="'SEARCH_USER_TO_CHAT_SEARCH_PREFIX'"
      [placeholderTranslation]="'SEARCH_USER_TO_CHAT_SEARCH_PLACEHOLDER'" [selectedItems]="selectedChips$ | async"
      [maxNumberOfSelectableItems]="maxNumberOfSelectableUsers" (searchTermChange)="onSearchChange($event)"
      (itemRemove)="onItemRemove($event)" [itemCount]="selectedChipsCount$ | async">
    </wen-input-with-chips>
    <div class="wen-chat-creation-list-container" tabindex="-1" portalDragOverlay [overlayContent]="overlayContent"
      (fileDropped)="onFilesDropped($event)">
      <wen-chat-selection-list [selectionModeEnabled]="selectionModeEnabled$ | async"
      (listItemClicked)="handleListItemClick($event)"></wen-chat-selection-list>
    </div>
    @if (showForwardButton$ | async) {
      @if ((selectedChipsCount$ | async) > 0) {
        <wen-action class="wen-action-round wen-forward wen-action-no-padding" wenVariant="filled"
          iconName="send" iconSize="very-big" (click)="forward()">
        </wen-action>
      }
    } @else {
      <wen-chat-message-send-field class="wen-chat-creation-bottom-controls"
      [keepInputFocused]="false"></wen-chat-message-send-field>
    }
  </div>
</wen-loading-skeleton>
