@if (currentChannel$ | async; as currentChannel) {
  <div class="wen-channel-view-desktop-header-content" (click)="openDetail(currentChannel.id)">
    <wen-active-item-image-view [overlaySize]="'medium'"></wen-active-item-image-view>
    <div class="wen-channel-view-desktop-header-info">
      <div class="wen-channel-view-desktop-header-info-title">
        @if (currentChannel.isPrivate && !currentChannel.subscribed) {
          <wen-icon class="wen-icon-small" iconName="lock" wenVariant="assistiveInlined"
                    [altText]="'CHANNEL_RESTRICTED_ICON' | translate"></wen-icon>
        }
        <h2 portal-title>{{ currentChannel.title }}</h2>
        @if (currentChannel.isMuted) {
          <wen-icon class="wen-icon-small" iconName="mute" wenVariant="assistiveInlined"
                    [altText]="'CHANNEL_MUTED_ICON' | translate"></wen-icon>
        }
      </div>
      @if (currentChannel.channelType === channelType.NORMAL) {
        <div portal-body-2>{{ currentChannel.subscriptions }} {{'SUBSCRIBER' | translate: {count: currentChannel.subscriptions} }}
        </div>
      }
    </div>
  </div>
}
