import { inject, Injectable, NgModule, StaticProvider } from '@angular/core';
import { ChannelDTO, SocketIoService } from '@portal/wen-backend-api';
import { MASS_DATA_CONFIG, MassDataConfigEntry, MassDataContainer, MassDataModule, MassDataRepository, MassDataStateAdapter, OffsetBasedMassDataPagingAdapter, PageRequestDescriptor } from '@portal/wen-data-core';
import { map } from 'rxjs';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { OtherProfileData } from '../../../../../views/user-profile/profile-form/components/other-profile/providers/profile-data.service';
import { otherProfileRecommendationsMassDataIdentifier } from '../../constants';
import { Store } from '@ngrx/store';


@Injectable()
export class OtherProfileRecommendationsMassDataContainer extends MassDataContainer<ChannelDTO> {

  getIdentifier() {
    return otherProfileRecommendationsMassDataIdentifier;
  }

}

@Injectable()
export class OtherProfileRecommendationsMassDataAdapter extends MassDataStateAdapter<ChannelDTO> {

  private otherProfileRecommendationsEntityAdapter: EntityAdapter<ChannelDTO>;

  getEntityAdapter() {
    if (!this.otherProfileRecommendationsEntityAdapter) {
      this.otherProfileRecommendationsEntityAdapter = createEntityAdapter<ChannelDTO>({
        selectId: (channel) => channel.id
      });
    }
    return this.otherProfileRecommendationsEntityAdapter;
  }

}

@Injectable()
export class OtherProfileRecommendationsMassDataRepository extends MassDataRepository<OtherProfileRecommendationsMassDataContainer> {

  private socketIoService = inject(SocketIoService);
  private store = inject(Store);

  fetchPage(pagingParams: PageRequestDescriptor) {
    return this.socketIoService.channel.recommendationsList.acknowledgement$(pagingParams).pipe(
      map((response) => {
        return {
          data: response.page.content
        };
      })
    );
  }

}

const otherProfileRecommendationsConfig: MassDataConfigEntry = {
  identifier: otherProfileRecommendationsMassDataIdentifier,
  container: OtherProfileRecommendationsMassDataContainer,
  adapter: OtherProfileRecommendationsMassDataAdapter,
  repository: OtherProfileRecommendationsMassDataRepository,
  pagingAdapter: OffsetBasedMassDataPagingAdapter,
};

const OTHERPROFILE_RECOMMENDATIONS_CONTAINER_CONFIG: StaticProvider = {
  provide: MASS_DATA_CONFIG,
  multi: true,
  useValue: otherProfileRecommendationsConfig,
};

@NgModule({
  imports: [
    MassDataModule,
  ],
  providers: [
    OTHERPROFILE_RECOMMENDATIONS_CONTAINER_CONFIG,
    OtherProfileRecommendationsMassDataContainer,
    OtherProfileRecommendationsMassDataAdapter,
    OtherProfileRecommendationsMassDataRepository,
    OtherProfileData
  ]
})
export class OtherProfileRecommendationsMassDataModule { }
