<ng-container #containerRef></ng-container>

<ng-template #default>
  <wen-dialog-layout [headerGravity]="'top'" [wen-test-id]= "'filter-selector'">
    <h2 wen-dialog-layout-static-header class="wen-filter-selector-header" id="dialogHeaderLabel">{{ filterSelectorHeader | translate }}</h2>
    <div dialogLayoutContent>
      <div class="wen-filter-selector-form-group">
        @if (filterSelectionListData$ | async; as data) {
          <wen-selection-list class="wen-borderless-group" [selectionItems]="data.items"
            [selectionModel]="data.model" [selectionOptionPosition]="'postfix'"
            [selectionOptionBorderType]="isMultiSelection ? 'normal' : 'borderless'"
            (selectionChanged)="onSelection($event)">
          </wen-selection-list>
        }
      </div>
    </div>
    <div wen-dialog-layout-static-footer>
      <mat-dialog-actions class="wen-filter-dialog-actions">
        @if (isMultiSelection) {
          <button class="wen-filter-dialog-button" wen-primary-color (click)="onApply()">{{ "APPLY_BUTTON" | translate }}</button>
          <button class="wen-filter-dialog-button" wen-primary-color (click)="onReset()">{{ "FILTER_RESET_BUTTON_LABEL" | translate }}</button>
        } @else {
          <button class="wen-filter-dialog-button" wen-primary-color (click)="onCancel()">{{ "CANCEL_BUTTON_LABEL" | translate }}</button>
        }
      </mat-dialog-actions>
    </div>
  </wen-dialog-layout>
</ng-template>