import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Observable, first, map, shareReplay, switchMap } from 'rxjs';
import { FilterId } from '../../../../../../core/common/types/filter-id';
import { selectorWithParam } from '../../../../../../core/common/util/selector-with-param';
import { selectActiveFilterById } from '../../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../../core/store/filter/models/filter';
import { RootState } from '../../../../../../core/store/root/public-api';
import { FilterSelectorOpener } from '../../../../../../shared/components/filter-view/components/filter-selector/filter-selector-opener';
import { createFilterFromCategory } from '../../../../../../shared/components/filter-view/models/category';
import { Filterable } from '../../../../../../shared/components/filter-view/models/filter-item';

@Injectable()
export class ChannelCategoryFilterDataSource {

  currentActiveChannelCategory$ = this.store.pipe(
    selectorWithParam(selectActiveFilterById, FilterEntityType.CHANNEL_LISTS, FilterId.CHANNEL_CATEGORIES),
    smartDistinctUntilChanged()
  );

  private filterPossibleValues$: Observable<Filterable[]>;

  get fallbackAllCategoryFilter(): Filterable {
    return { id: null, name: this.translateService.instant('FILTER_CATEGORIES_SELECTOR') };
  }

  constructor(
    readonly socketIoService: SocketIoService,
    readonly store: Store<RootState>,
    private translateService: TranslateService,
    private filterSelectorOpener: FilterSelectorOpener,
  ) {
    this.filterPossibleValues$ = this.loadCategories().pipe(
      shareReplay(1)
    );
  }

  private loadCategories() {
    return this.socketIoService.channel.getCategories.acknowledgement$().pipe(
      first(),
      map(categories => categories.map(c => createFilterFromCategory(c)))
    );
  }

  openFilterSelector(activeCategory: Filterable) {
    return this.filterPossibleValues$.pipe(
      switchMap((possibleValues) => {
        const dialogData = {
          visibleFilters: possibleValues,
          activeFilterIds: [activeCategory?.id],
          isMultiselect: true
        };
        return this.filterSelectorOpener.openFilterSelector(dialogData);
      })
    );
  }
}
