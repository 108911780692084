import { DataSource } from '@angular/cdk/collections';
import { ChannelDTO, SocketIoService } from '@portal/wen-backend-api';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class OtherProfileRecommendationsSectionDatasource implements DataSource<ChannelDTO> {

  private sectionItems = new BehaviorSubject<ChannelDTO[]>([]);
  private onDisconnect$ = new Subject<void>();

  constructor(private socketIoService: SocketIoService) { }

  connect(): Observable<ChannelDTO[]> {
    return this.sectionItems.asObservable();
  }

  disconnect(): void {
    this.onDisconnect$.next();
    this.onDisconnect$.complete();
    this.sectionItems.complete();
  }

  loadUserRecommendations(userId: string) {
    this.socketIoService.channel.recommendationsList.acknowledgement$({ paging: { offset: 0, size: 15 } }).pipe(
      take(1),
      takeUntil(this.onDisconnect$)
    ).subscribe(
      recommendations => this.sectionItems.next(recommendations.page.content)
    );
  }

}
