@if (hasValue) {
  <div class="wen-media-preview-wrapper">
    <div class="wen-media-preview-image-wrapper">
      <div class="wen-media-preview" [ngClass]="{'wen-media-icon': mediaIconName}">
        @if (mediaSrc) {
          <img [src]="mediaSrc" (error)="onImageError()" alt=""/>
        }
        @if (mediaIconName) {
          <wen-icon [class.wen-icon-huge]="fitStyle" [iconName]="mediaIconName"></wen-icon>
        }
      </div>
      @if (dismiss.observed) {
        <wen-icon wen-primary-background-color [class.wen-icon-huge]="fitStyle" class="wen-icon-medium wen-media-preview-remove" iconName="close"
        (click)="emitDismiss()"></wen-icon>
      }
    </div>
    @if (!mediaError) {
      <div class="wen-media-preview-details">
        <div [wen-middle-ellipsize]="7" [text]="mediaFileName"></div>
        <div>{{ mediaFileSize | filesize }}</div>
      </div>
    }
  </div>
}
