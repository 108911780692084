import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { updateChatDraftMessage, updateDraftChatMessage } from '../../../../core/store/chat/chat.actions';
import { selectCurrentRoom, selectDraftChatMessageById } from '../../../../core/store/chat/chat.selectors';
import { updateDraftChannelMessage } from '../../../../core/store/channel/channel.actions';
import { DraftMessageEntity } from '../../../../core/store/common/models';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem, PollCreationComponent, OverlayManager } from '@portal/wen-components';
import { distinctUntilChanged, filter, first, map, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { selectCurrentChannel, selectCurrentChannelDraftMessage } from '../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { EmbedDTOType } from '@portal/wen-backend-api';
import { mapWithFirstFrom } from '../../../../core/common/operators/map-with-first-from';

export abstract class PollCreationAction implements ActionMenuItem {

  label = '';
  icon = 'chart';

  isVisible$ = () => of(true);

  constructor(
    protected store: Store<RootState>,
    protected overlayManager: OverlayManager,
    protected translateService: TranslateService,
  ) {
    this.label = this.translateService.instant('CONTEXT_MENU_ACTION_POLL_LABEL');
  }

  execute() {
    const dialogRef = this.overlayManager.dialog.openInputDialog(PollCreationComponent, null);
    dialogRef.afterClosed().pipe(
      first(),
      filter(poll => Boolean(poll)),
      withLatestFrom(this.selectDraftMessageContextId())
    ).subscribe(([poll, contextId]) => {
      const message: DraftMessageEntity = {
        contextId,
        pollEmbed: {
          type: EmbedDTOType.POLL,
          pollId: null,
          poll
        }
      };
      this.store.dispatch(
        this.updateDraftMessageAction(message)
      );
    });
  }

  abstract selectDraftMessageContextId(): Observable<string>;
  abstract updateDraftMessageAction(message: DraftMessageEntity);
}

@Injectable()
export class ChannelPollCreationAction extends PollCreationAction {

  isVisible$ = () => this.store.pipe(
    select(selectCurrentChannelDraftMessage),
    map(currentDraft => Boolean(currentDraft?.id)),
    distinctUntilChanged(),
    map(isEditingMessage => !isEditingMessage),
  );

  constructor(
    store: Store<RootState>,
    overlayManager: OverlayManager,
    translateService: TranslateService,
  ) {
    super(store, overlayManager, translateService);
  }

  selectDraftMessageContextId(): Observable<string> {
    return this.store.pipe(select(selectCurrentChannel)).pipe(
      map(channel => channel.id)
    );
  }

  updateDraftMessageAction(message: DraftMessageEntity) {
    return updateDraftChannelMessage({ message });
  }
}

@Injectable()
export class ChatPollCreationAction extends PollCreationAction {

  isVisible$ = () => this.selectDraftMessageContextId().pipe(
    switchMap((id) => this.store.pipe(selectorWithParam(selectDraftChatMessageById, id))),
    map(currentDraft => Boolean(currentDraft?.id)),
    distinctUntilChanged(),
    mapWithFirstFrom(() => this.featureEnablementService.featureConfigObs.enablePollsForChat$),
    map(([isEditingMessage, isFeatureEnabled]) => isFeatureEnabled && !isEditingMessage),
  );

  constructor(
    store: Store<RootState>,
    overlayManager: OverlayManager,
    translateService: TranslateService,
    private featureEnablementService: FeatureEnablementService
  ) {
    super(store, overlayManager, translateService);
  }

  selectDraftMessageContextId(): Observable<string> {
    return this.store.pipe(select(selectCurrentRoom)).pipe(
      map(room => room.id)
    );
  }

  updateDraftMessageAction(message: DraftMessageEntity) {
    return updateChatDraftMessage({ message });
  }
}

@Injectable()
export class ChatBroadcastPollCreationAction extends PollCreationAction {
  constructor(
    store: Store<RootState>,
    overlayManager: OverlayManager,
    translateService: TranslateService,
    private featureEnablementService: FeatureEnablementService
  ) {
    super(store, overlayManager, translateService);
  }

  isVisible$ = () => this.featureEnablementService.featureConfigObs.enablePollsForChat$;

  selectDraftMessageContextId(): Observable<string> {
    return of(null);
  }

  updateDraftMessageAction(message: DraftMessageEntity) {
    return updateDraftChatMessage({ message });
  }
}
